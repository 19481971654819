import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import ProductSlider from 'blocks/ProductSlider';
import Videos from 'blocks/Videos';

import {
  Spacing,
  Container,
  SharedStyles,
} from '@matchbox-mobile/kenra-storybook';
const { StTitle, StPageTitle, StImgDesc, StInnerText } = SharedStyles;

export default function BlogPost({ page }) {
  const { topic, title, text, products, videos, videoImage } = page;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        let img = get(node, 'data.target.fields.file["en-US"].url');
        let title = get(node, 'data.target.fields.title["en-US"]');

        if (!img) return null;

        return (
          <StImgDesc>
            <div>
              <img src={img} alt={title} />
              <figcaption>{title}</figcaption>
            </div>
          </StImgDesc>
        );
      },
    },
  };

  return (
    <>
      <Spacing removeSpaceBottom>
        <Container>
          <StPageTitle>
            <span>{topic}</span>
            <StTitle>
              <h1>{title}</h1>
            </StTitle>
          </StPageTitle>

          <StInnerText>
            {documentToReactComponents(get(text, 'json'), options)}
          </StInnerText>
        </Container>
      </Spacing>

      {videos && (
        <Spacing>
          <Videos
            videos={videos}
            title={'Related Videos'}
            showBottomButton={false}
            bgImage={get(videoImage, 'localFile.publicURL')}
          />
        </Spacing>
      )}

      {products && (
        <Spacing>
          <ProductSlider
            productHandles={products.map(p => p.shopifyProduct.handle)}
          />
        </Spacing>
      )}
    </>
  );
}
