import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import BlogPost from 'components/BlogPost';

export default props => {
  let { contentfulBlogPost } = props.data;
  if (!contentfulBlogPost) return 'No blog post';

  return (
    <Layout>
      <BlogPost page={contentfulBlogPost} />
    </Layout>
  );
};

export const query = graphql`
  query($contentful_id: String!) {
    contentfulBlogPost(contentful_id: { eq: $contentful_id }) {
      ...ContentfulBlogPostFields
    }
  }
`;
